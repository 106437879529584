var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('OrganizationSettingsStyled', [_c('label', [_vm._v(_vm._s(_vm.$tc('alias', 1)))]), _c('input', {
    attrs: {
      "disabled": true
    },
    domProps: {
      "value": _vm.organization.alias
    }
  }), _c('label', [_vm._v(_vm._s(_vm.$tc('name', 1)))]), _c('textarea', {
    attrs: {
      "disabled": true
    },
    domProps: {
      "value": _vm.organization.name
    }
  }), _vm._v(" "), _c('label', [_vm._v(_vm._s(_vm.$tc('picture', 1)))]), _c('ImageUploader', {
    attrs: {
      "single": true,
      "image": _vm.organization.picture
    },
    on: {
      "uploaded": _vm.handleUpload,
      "disconnect": _vm.handleDisconnect
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }