<template>
  <OrganizationSettingsStyled>
    <label>{{ $tc('alias', 1) }}</label>
    <input :disabled="true" :value="organization.alias" />
    <label>{{ $tc('name', 1) }}</label>
    <textarea :disabled="true" :value="organization.name" />
    <label>{{ $tc('picture', 1) }}</label>
    <ImageUploader :single="true" :image="organization.picture" @uploaded="handleUpload" @disconnect="handleDisconnect" />
  </OrganizationSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { ImageUploader } from '@common/components'

import { flexCenter, input } from '@styles/mixins'

const OrganizationSettingsStyled = styled('div')`
  grid-column: span 2;
  display: grid;
  width: calc(100% - 2rem);
  padding: 1rem;
  margin-top: 1rem;
  background: ${p => p.theme.colors.solidBGDarker};
  grid-auto-rows: minmax(50px, auto);
  grid-template-columns: 100px auto;
  > label {
    ${flexCenter}
  }
  > input,
  > textarea {
    ${input}
    margin: .5rem 0;
  }
`

export default {
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  components: {
    OrganizationSettingsStyled,
    ImageUploader,
  },
  methods: {
    async handleUpload(/*image*/) {
      // this.$set(this.profile, 'picture', image)
      // const res = await this.$apollo.mutate({
      //   mutation: UPDATE_ORGANIZATION_PICTURE_MUTATION,
      //   variables: {
      //     imageID: image.id,
      //     organizationID: this.organization.id,
      //   },
      // })
      // const url = get(res, 'data.updateOrganizationPicture.picture.url')
      // if (url) {
      //   // this.$apollo.queries.profile.refetch()
      // }
    },
    async handleDisconnect() {
      // await this.$apollo.mutate({
      //   mutation: DISCONNECT_ORGANIZATION_PICTURE_MUTATION,
      //   variables: {
      //     organizationID: this.organization.id,
      //   },
      // })
      // this.$apollo.queries.profile.refetch()
    },
  },
}
</script>
